import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../BaseURL';
import { apiConnector } from '../../../services/apiConnector';
import { setLoading } from '../../../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../Loading/Loader.jsx";
import toast from 'react-hot-toast';
import "./AllDataTable.css";
import { Link } from 'react-router-dom';
import { BsPencilSquare } from "react-icons/bs";
import { RiInformationLine } from 'react-icons/ri';

const AllDataTable = () => {
    const [leadData, setLeadData] = useState([]);
    const {loading} = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.profile);
    const dispatch = useDispatch();
  
    const Id = user._id;

    const getAllLeads = async()=>{
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("get",`${BASE_URL}/telecaller/get-one-telecaller/${Id}`);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            setLeadData(response.data.data.UsersData);

        } catch (error) {
            console.log("Can't fetch Data due to",error);
            toast.error("Leads data can't be fetched");
        }
        dispatch(setLoading(false));
    }

    useEffect(()=>{
      getAllLeads();
    },[])

  return (
    <div className="flex flex-col " >
   <div>

    {
      loading && (<Loader/>)
    }

   { !loading &&
    leadData.length >0 && (<div className="p-1.5 w-full inline-block align-middle">
    <div className="overflow-hidden border rounded-lg">
      <table className="w-full divide-y divide-gray-200">
        <thead className="bg-blue-500 w-full text-white ">
          <tr>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Name
            </th>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Email
            </th>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Mobile
            </th>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Source
            </th>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Services
            </th>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Date
            </th>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Time
            </th>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Next Calling Date
            </th>
            {/* <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Follow Up Result
            </th> */}
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Response
            </th>
            <th
              scope="col"
              className="px-4
               py-2 text-[12px] font-bold text-left uppercase "
            >
              Update
            </th>
          </tr>
        </thead>
        {/* table body */}
        {
            loading && (
                <div className='w-full' >
                    <Loader/>
                </div>
            )
        }
        {
            !loading && (
              leadData?.map((User,index)=>(
                <tbody className="divide-y divide-gray-200" key={index} >
    <tr className='bg-gray-50' >
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {User?.name}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">{User?.email}</td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.mobile}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.source}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.services}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.date}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.time}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.followDate}
            </td>
            {/* <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.followResult}
            </td> */}
            {
             User?.response === 'Interest'
              && (<td className="px-4
             py-3 text-[12px] text-green-500 font-bold whitespace-nowrap ">
            {User?.response}
            </td>)
            }
            {
             User?.response === 'Not Pick'
              && (<td className="px-4
             py-3 text-[12px] text-yellow-500 font-bold whitespace-nowrap ">
            {User?.response}
            </td>)
            }
            {
             User?.response === 'Call Back'
              && (<td className="px-4
             py-3 text-[12px] text-violet-500 font-bold whitespace-nowrap ">
            {User?.response}
           
            </td>)
            }
            {
             User?.response === 'Not Interested'
              && (<td className="px-4
             py-3 text-[12px] text-red-500 font-bold whitespace-nowrap ">
            {User?.response}
            </td>)
            }
             <td className="px-4 text-[12px] font-semibold whitespace-nowrap">
                         <Link to={`/update-userdata/${User?._id}`}
                         >
                             <button className=" px-4 py-2 rounded-md  text-white bg-blue-500 hover:bg-blue-600">
                             <BsPencilSquare />
                             </button>
                         </Link>
                       </td>
          </tr>
        </tbody>
            ))
            )
        }
      </table>
    </div>
  </div>)
   }

{
      !loading && leadData.length === 0 && (
      <div className=' flex items-center justify-center gap-2 bg-blue-200 px-2 py-2 rounded-md text-blue-600 ' >
        <RiInformationLine/>
        <p >No Data Assigned</p>
      </div>)
    }
   </div>
 </div>
  )
};

export default AllDataTable