import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { IoArrowBackCircleSharp } from "react-icons/io5";
import {getPasswordResetToken} from "../services/authAPI";
import Loader from '../Loading/Loader';

const ForgotPassword = () => {

    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState("");
    const {loading} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const handleOnSubmit = (e) => {
        e.preventDefault();
        dispatch(getPasswordResetToken(email, setEmailSent));
    }

  return (
    <div className='w-full h-[100vh] flex gap-4  ' >
    {/* left */}
    <div className=' w-[30%] bg-[#081321] h-full flex flex-col pt-6 pb-7 gap-[200px] ' >
    <p className=' font-poppins font-[600] text-white text-lg lg:text-xl uppercase text-center ' >Lead Generation</p>
    
    <div className='flex flex-col gap-4 items-center font-poppins ' >
        <p className='font-[600] text-blue-500 text-lg lg:text-xl' >Plan Includes</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Unlimited Employee Update</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Full Authentication</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Manage Everything Easily</p>

    </div>
    
    </div>

    {/* right */}
    <div className=' w-[70%] h-full flex flex-col p-5 ' >
    <p className='mt-5 text-end text-[14px] text-gray-500 ' >Don't Have an account ? {" "}
                <span className="text-blue-500"  >Contact Admin</span>
    </p>
    <div className='w-full h-full relative flex flex-col gap-[100px] overflow-x-hidden font-poppins' >

<div className=' w-11/12 mx-auto flex text-richblack-5 justify-center items-center mt-[100px] ' >
{
    loading ? (
        <Loader/>
    ) : (
        <div className='flex flex-col gap-3 justify-center w-[370px] ' >
            <h1 className='font-semibold text-3xl ' >
                {
                    !emailSent ? "Reset your Password" : "Check Your Email"
                }
            </h1>
            
            <p className='text-richblack-100 text-md' >
            {
                !emailSent ? "Have no fear. We’ll email you instructions to reset your password. If you dont have access to your email we can try account recovery" 
                            : `We have sent the reset email to ${email}`
            }
            </p>

            <form onSubmit={handleOnSubmit} >
                {
                    !emailSent && (
                        <div className='flex flex-col gap-4 ' >
                            <div>
                                <label htmlFor='email' >
                                    <p className=' text-sm text-richblack-5 mb-1 leading-[1.375rem]' >Email Address <sup className='text-[#FF0000] ' >*</sup> </p>  </label>
                                    <input
                                        required
                                        type='email'
                                        name='email'
                                        value={email}
                                        onChange={ (e) => setEmail(e.target.value)}
                                        className="w-full rounded-[0.5rem] outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                        placeholder='Enter Your Email Address'
                                    />
                            </div>
                        </div>
                    )
                }
                {
                    <button  className=" w-full mt-5 rounded-[8px] bg-blue-500 transition-all duration-200 hover:bg-blue-600 py-[8px] px-[12px] font-bold text-richblack-900"
                                type='submit'
                                >
                                {
                                        !emailSent ? "Reset Password" : "Resend Email"
                                }
                                </button>
                }
            </form>

            <div>
                <Link to="/" >
                    <div className='flex flex-row items-center gap-1 ' >
                         <IoArrowBackCircleSharp />
                        <p> Back to Login</p>
                    </div>
                </Link>
            </div>

        </div>
    )
}
</div>
</div>
    </div>
   </div>
  )
}

export default ForgotPassword