import React from 'react'
import TodayTable from './TodayTabel'

const TodayData = () => {
  return (
    <div className=' w-full h-full flex flex-col gap-2 mt-3 ' >
        <h1 className=' text-md md:text-lg font-semibold ' >Today's Assigned Leads</h1>
        <div className=' h-[1px] w-full bg-slate-300 ' ></div>
        <TodayTable/>
    </div>
  )
}

export default TodayData