import React, { useState } from 'react'
import LoginImg from "../assests/log.png";
import mailImg from "../assests/mail.png";
import PassImg from "../assests/pass.png";
import phoneImg from "../assests/phone.png";
import userImg from "../assests/user.png";
import {Link} from "react-router-dom";
import {toast} from "react-hot-toast";
import {useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { setSignupData } from '../slices/authSlice';
import { register } from '../services/authAPI';


const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        fullName:'',
        email:'',
        password:'',
        confirmPassword:'',
        phoneNumber:'',
    });

    const handleInputChange = (e) =>{
        setFormData({...formData,
            [e.target.name]: e.target.value
    })}

    const [accountType, setAccountType] = useState('User');

    const handleToggleAdmin = (e) => {
      e.preventDefault();
          setAccountType('Admin');
    };
  
    const handleToggleUser = (e) => {
      e.preventDefault();
          setAccountType('User');
    };

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(formData.password !== formData.confirmPassword) {
            toast.error("Passwords Do Not Match")
            return
        }
       
        dispatch(register(formData.fullName, 
            formData.email, 
            formData.password,
            formData.confirmPassword,
            formData.phoneNumber,
            accountType,
             navigate));
        dispatch(setSignupData(formData));

        //Reset 
        setFormData({
            fullName: "",
            email: "",
            password: "",
            confirmPassword: "",
            phoneNumber:""
        })
    }


  return (
    <div className='w-full h-[100vh] flex gap-4 ml-[-100px] ' >
         <div className=' w-[30%] bg-[#081321] h-full flex flex-col pt-6 pb-7 gap-[200px] ' >
    <p className=' font-poppins font-[600] text-white text-lg lg:text-xl uppercase text-center ' >Lead Generation</p>
    
    <div className='flex flex-col gap-4 items-center font-poppins ' >
        <p className='font-[600] text-blue-500 text-lg lg:text-xl' >Plan Includes</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Unlimited Employee Update</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Full Authentication</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Manage Everything Easily</p>

    </div>
    
         </div>

          {/* Content */}
    <div className='w-[70%] h-full flex flex-col px-10 py-5 justify-center items-center ' >
        <p className='font-medium text-[22px]' >Welcome to Lead Generation</p>
        <h2 className='font-bold text-[20px] text-[#5D59D9] font-poppins '
        >Create an Account</h2>
        <div className='w-[70%] h-[1px] bg-gray-300 mt-1 mb-2 ' ></div>
        <form className='flex flex-col gap-2' >
        <div className="flex p-1 gap-x-1 my-2 rounded-full max-w-max">
                <button
                        className={`${accountType === "User"
                        ?"bg-black text-white" 
                        : "bg-transparent text-gray-600"}
                        py-2 px-5 rounded-full transition-all duration-200`}
                        onClick={handleToggleUser}
                    >
                        User
                </button>
                <button
                    className={`${accountType === "Admin"
                    ? "bg-black text-white"
                    : "bg-transparent text-gray-400"} 
                    py-2 px-5 rounded-full transition-all duration-200`}
                    onClick={handleToggleAdmin}
                >
                    Admin
                </button>
            </div>
           <div className='grid grid-cols-1 lg:grid-cols-2 gap-1' >
           <div className='flex gap-6 bg-[#ECECEC] px-6 py-4 rounded-md mt-4' >
                    <div className='flex items-center ' >
                        <img src={userImg} width="25px" alt='user Image' loading='lazy' />
                    </div>
                    <div className='w-[100%] flex flex-col gap-1' >
                        <label id='fullName' className=' font-poppins text-[14px]' >Full Name</label>
                        <input
                            type='text'
                            id='fullName'
                            name='fullName'
                            placeholder='Enter your full name'
                            value={formData.fullName}
                            onChange={handleInputChange}
                            className=' w-[100%] rounded-md bg-transparent font-bold text-[12px] outline-none '
                        />
                    </div>
                </div>
            <div className='flex gap-8 bg-[#ECECEC] px-6 py-4 rounded-md mt-4' >
                <div className='flex items-center' >
                    <img src={mailImg} alt='Email Image' width="25px" loading='lazy' />
                </div>
                <div className='w-[100%] flex flex-col gap-1' >
                    <label id='email' className=' font-poppins text-[14px]' >Email</label>
                    <input
                        type='email'
                        id='email'
                        name='email'
                        placeholder='abc@gmail.com'
                        value={formData.email}
                        onChange={handleInputChange}
                        className=' w-[100%] rounded-md bg-transparent font-bold text-[12px] outline-none '
                    />
                </div>
            </div>
            <div className='flex gap-8 bg-[#ECECEC] px-6 py-4 rounded-md mt-4 ' >
                <div className='flex items-center' >
                    <img src={PassImg} loading='lazy' width="25px" alt='Password Image' />
                </div>
                <div className='w-[100%] flex flex-col gap-1' >
                    <label id='password' className=' font-poppins text-[14px]' >Password</label>
                    <input
                        type='password'
                        id='password'
                        name='password'
                        placeholder='Enter your password'
                        value={formData.password}
                        onChange={handleInputChange}
                        className=' w-[100%] rounded-md bg-transparent font-bold text-[12px] outline-none '
                    />
                </div>
            </div>

            <div className='flex gap-8 bg-[#ECECEC] px-6 py-4 rounded-md mt-4 ' >
                <div className='flex items-center' >
                    <img src={PassImg} loading='lazy' width="25px" alt='Password Image' />
                </div>
                <div className='w-[100%] flex flex-col gap-1' >
                    <label id='confirmPassword' className=' font-poppins text-[14px]' >Confirm Password</label>
                    <input
                        type='password'
                        id='confirmPassword'
                        name='confirmPassword'
                        placeholder='Confirm your password'
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        className=' w-[100%] rounded-md bg-transparent font-bold text-[12px] outline-none '
                    />
                </div>
            </div>

            <div className='flex gap-6 bg-[#ECECEC] px-6 py-4 rounded-md mt-4' >
                    <div className='flex items-center' >
                        <img src={phoneImg} width="25px" alt='Phone Image' loading='lazy' />
                    </div>
                    <div className='w-[100%] flex flex-col gap-1' >
                        <label id='phoneNumber' className=' font-poppins text-[14px]' >Phone Number</label>
                        <input
                            type='number'
                            id='phoneNumber'
                            name='phoneNumber'
                            placeholder='Enter mobile number'
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                            className=' w-[100%] rounded-md bg-transparent font-bold text-[12px] outline-none '
                        />
                    </div>
                </div>
           </div>
         
            <button onClick={handleSubmit} 
            className='bg-blue-500 mt-5 font-semibold text-white px-2 py-3 rounded-md
            hover:bg-blue-600 transition-all duration-300' >Create an account</button>
        </form>
        <p className='mt-5 text-center text-[14px] text-gray-500 ' >Already Have an account ? {" "}
            <Link to="/login">
                <span className="text-[#5D59D9]" >Login</span>
            </Link>
        </p>
    </div>
   
</div>
  )
}

export default Register