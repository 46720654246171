import {toast} from "react-hot-toast";
import { setLoading } from "../slices/authSlice";
import { apiConnector } from "./apiConnector";
import { BASE_URL } from "../BaseURL.js";

export function updateUserDetail( 
            id,
            name,
            email,
            mobile,
            services,
            result,
            response,
            source,
            followDate,
            followResult,
            date,
            time,
            navigate,
    ){
return async (dispatch) =>{
    const toastId = toast.loading("Loading...")
    dispatch(setLoading(true))
    try {
        const responsedata = await apiConnector("PUT",`${BASE_URL}/user/update-user`,{
            id,
            name,
            email,
            mobile,
            services,
            result,
            response,
            source,
            followDate,
            followResult,
            date,
            time
        });


        if(!responsedata.data.success){
            throw new Error(responsedata.data.message)
        }

        toast.success("User Data Updated Successfully");
        window.location.reload();

    } catch (error) {
        console.log("Can't Updated Data due to",error);
        toast.error("User data can't Updated");
    }
    dispatch(setLoading(false))
    toast.dismiss(toastId)
}
}