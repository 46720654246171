import AdminTable from './AdminTable';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react'
import {BASE_URL} from "../../BaseURL.js" ;
import { apiConnector } from '../../services/apiConnector.js';
import { setLoading } from '../../slices/authSlice.js';
import toast from 'react-hot-toast';
import { formateDate } from '../../utils/formateDate.jsx';
import './AdminTable.css';
import TodaySale from "../../pages/Dashboard/SaleComponent/TodaySale.jsx"
import { Link } from 'react-router-dom';
import { formateTime } from '../../utils/formateTime.js';
import Loader from '../../Loading/Loader.jsx';

const AdminTodaySale = () => {
    const date = new Date();
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const weeks = ["Sunday","Monday","Tuesday","Thursday","Friday","Saturday"];
  const d = new Date();
  let month = months[d.getMonth()];
  let day = weeks[d.getDay()];
  let dat = d.getDate();

  const [saleData, setSaleData] = useState([]);
  const {loading} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
 
  const getAllSales = async()=>{
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("get",`${BASE_URL}/upload/importuser`);

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          setSaleData(response.data.data);

      } catch (error) {
          console.log("Can't fetch Data due to",error);
          toast.error("Sale data can't be fetched");
      }
      dispatch(setLoading(false));
  }

  useEffect(()=>{
      getAllSales();
  },[])

  const todayDate = new Date().toISOString().split('T')[0];

    // Filter data for today's date
    const todayData = saleData.filter(item => item.uploadedOnDate.startsWith(todayDate));

    
    let paidMoney=0,totalMoney=0;
    todayData?.forEach(obj => {
      if(obj.advanceAmount!==0){
        paidMoney+=obj.advanceAmount;
      }
      if(obj.advanceAmount===0){
        paidMoney+=obj.amount;
      }

      totalMoney+=obj.amount;
    });

  return (
    <div className="flex flex-col " >
    <div >
    <div className="p-1.5 w-full inline-block align-middle">
       <div className="overflow-hidden border rounded-lg">
         <table className="w-full divide-y divide-gray-200">
           <thead className="bg-blue-500 w-full text-white ">
             <tr>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Name
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Email
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Mobile
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Source
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Services
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Date
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Time
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Next Calling Date
               </th>
               {/* <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Follow Up Result
               </th> */}
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Result
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Assigned To
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Response
               </th>
             </tr>
           </thead>
           {/* table body */}
           {
               loading && (
                   <div className='w-full' >
                       <Loader/>
                   </div>
               )
           }
           {
               !loading && (
                 todayData?.map((user,index)=>(
                   <tbody className="divide-y divide-gray-200" key={index} >
       <tr className='bg-gray-50' >
               <td className="px-4
                py-3 text-[12px] font-semibold text-gray-800 whitespace-nowrap">
                 {user?.name}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">{user?.email}</td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.mobile}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.source}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.services}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.date}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.time}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.followDate}
               </td>
               {/* <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.followResult}
               </td> */}
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.result}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.assignedTo}
               </td>
               {
                user?.response === 'Interest'
                 && (<td className="px-4
                py-3 text-[12px] text-green-500 font-bold whitespace-nowrap">
               {user?.response}
               </td>)
               }
               {
                user?.response === 'Not Pick'
                 && (<td className="px-4
                py-3 text-[12px] text-yellow-500 font-bold whitespace-nowrap">
               {user?.response}
               </td>)
               }
               {
                user?.response === 'Call Back'
                 && (<td className="px-4
                py-3 text-[12px] text-violet-500 font-bold whitespace-nowrap">
               {user?.response}
               </td>)
               }
               {
                user?.response === 'Not Interested'
                 && (<td className="px-4
                py-3 text-[12px] text-red-500 font-bold whitespace-nowrap">
               {user?.response}
               </td>)
               }
           
             </tr>
           </tbody>
               ))
               )
           }
         </table>
       </div>
     </div>
    </div>
  </div>
  )
}

export default AdminTodaySale