import React, { useState } from 'react'
import CsvFileInput from '../CsvFileInput.jsx';
import file from "../../assests/file/sample.csv";

const UploadTelecaller = () => {

  const onButtonClick = () => {
    
    // using Java Script method to get PDF file
    fetch(`${file}`).then((response) => {
        response.blob().then((blob) => {
        
            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);
                
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "SampleFile.csv";
            alink.click();
        });
    });
};

  return (
    <div className=' flex flex-col gap-[50px] text-center mt-[50px] ' >
      <div>
      <CsvFileInput />
    </div>
        <div className=' flex flex-col gap-2 items-center ' >
          <h3 className=' font-semibold  ' >Note :- Here's Sample CSV File to Upload</h3>
           <button onClick={onButtonClick} 
           className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-200 '
           >
                    Download Sample
                </button>
        </div>
    </div>
  )
}

export default UploadTelecaller