import React from 'react'

const Attendence = () => {
  return (
    <div className=' w-full h-full flex flex-col gap-2 mt-3 ' >
    <h1 className=' text-md md:text-lg font-semibold ' >Attendence</h1>
    <div className=' h-[1px] w-full bg-slate-300 ' ></div>
    <p className=' font-poppins text-[#081321] text-center ' >Coming Soon...</p>
    </div>
  )
}

export default Attendence