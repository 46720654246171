import React from 'react'
import './Loader.css';

const Loader = () => {
  return (
    <div className='flex items-center justify-center mt-[200px] ' >
      <div className="loader "></div>
    </div>

  )
}

export default Loader