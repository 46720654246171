import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import LoginImg from "../assests/loge.gif";
import logo from "../assests/mainLogo.png";

import { RxHamburgerMenu } from "react-icons/rx";
import Login from './Login';

const HomePage = () => {
    const [toggle, setToggle] = useState(false);

    const handleToggle = () =>{
        setToggle(!toggle);
    }

  return (
   <div className='w-full h-[100vh] flex gap-4  ' >
    {/* left */}
    <div className=' w-[30%] bg-[#081321] h-full flex flex-col pt-6 pb-7 gap-[200px] ' >
    <p className=' font-poppins font-[600] text-white text-lg lg:text-xl uppercase text-center ' >Lead Generation</p>
    
    <div className='flex flex-col gap-4 items-center font-poppins ' >
        <p className='font-[600] text-blue-500 text-lg lg:text-xl' >Plan Includes</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Unlimited Employee Update</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Full Authentication</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Manage Everything Easily</p>

    </div>
    
    </div>

    {/* right */}
    <div className=' w-[70%] h-full flex flex-col p-5 gap-10 ' >
    <p className='mt-5 text-end text-[14px] text-gray-500 ' >Don't Have an account ? {" "}
                <span className="text-blue-500"  >Contact Admin</span>
    </p>
    <div className='w-[70%] mx-auto h-full flex justify-center items-center ' >
       <Login/>
    </div>
    </div>

   </div>
  )
}

export default HomePage