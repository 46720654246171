import React from 'react'
import { Link } from 'react-router-dom'
import { IoArrowBackCircleSharp } from "react-icons/io5";
import ResetImg from "../assests/reset.gif";

const ResetComplete = () => {
  return (
    <div className='w-full h-[100vh] flex gap-4  ' >
    {/* left */}
    <div className=' w-[30%] bg-[#081321] h-full flex flex-col pt-6 pb-7 gap-[200px] ' >
    <p className=' font-poppins font-[600] text-white text-lg lg:text-xl uppercase text-center ' >Lead Generation</p>
    
    <div className='flex flex-col gap-4 items-center font-poppins ' >
        <p className='font-[600] text-blue-500 text-lg lg:text-xl' >Plan Includes</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Unlimited Employee Update</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Full Authentication</p>
        <p className='font-[600] text-white text-sm lg:text-md' >Manage Everything Easily</p>

    </div>
    
    </div>

    {/* right */}
    <div className=' w-[70%] h-full flex flex-col p-5 ' >
    {/* <p className='mt-5 text-end text-[14px] text-gray-500 ' >Don't Have an account ? {" "}
                <span className="text-blue-500"  >Contact Admin</span>
    </p> */}
    <div className=' flex justify-center items-center ' >
        <img src={ResetImg} className=' w-[30%] ' />
    </div>
    <div className='w-[70%] mx-auto h-full mt-[-100px] flex justify-center items-center ' >
            <div className='w-[100%] relative flex flex-col gap-[100px] overflow-x-hidden font-poppins' >
            <div  className=' w-11/12 mx-auto flex text-richblack-5 justify-center items-center ' >
                <div className='flex flex-col gap-3 justify-center w-[370px] ' > 
                    <h1 className='font-semibold text-3xl text-blue-500 '>Reset Complete !</h1>
                    <p className='text-richblack-100 text-md' >{`All done! We have sent an email to your registered email to confirm`}</p>

                    <button className="mt-5 rounded-[8px] bg-blue-500 transition-all duration-200 hover:bg-blue-600 py-[8px] px-[12px] font-bold text-richblack-900"
                            >
                            <Link to="/" >
                                    <p>Return to Login</p>
                            </Link>
                    </button>
                    <div>
                                <Link to="/" >
                                    <div className='flex flex-row items-center gap-1 ' >
                                        <IoArrowBackCircleSharp />
                                        <p> Back to Login</p>
                                    </div>
                                </Link>
                    </div>
                </div>
            </div>
            </div>
    </div>
    </div>
   </div>
  )
}

export default ResetComplete