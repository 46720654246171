import React, { useEffect, useState } from 'react'
import {BASE_URL} from "../../BaseURL.js" ;
import { apiConnector } from '../../services/apiConnector.js';
import { setLoading } from '../../slices/authSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../Loading/Loader.jsx";
import toast from 'react-hot-toast';
import { formateDate } from '../../utils/formateDate.jsx';
import './AdminTable.css';
import { formateTime } from '../../utils/formateTime.js';

const FilterNameTable = () => {
    const [saleData, setSaleData] = useState([]);
    const {loading} = useSelector((state) => state.auth);
    const dispatch = useDispatch();
   
    const getAllSales = async()=>{
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("get",`${BASE_URL}/upload/importuser`);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            setSaleData(response.data.data);

        } catch (error) {
            console.log("Can't fetch Data due to",error);
            toast.error("Sale data can't be fetched");
        }
        dispatch(setLoading(false));
    }

    useEffect(()=>{
        getAllSales();
    },[])

  const [uniqueVNumbers, setUniqueVNumbers] = useState([]);
  const [uniqueOwnerNames, setUniqueOwnerNames] = useState([]);
  const [newfilteredData, setNewFilteredData] = useState(saleData);
  const [selectedVNumber, setSelectedVNumber] = useState('');
  const [selectedOwnerName, setSelectedOwnerName] = useState('');

  useEffect(() => {
    setUniqueVNumbers(getUniqueVNumbers(saleData));
    setUniqueOwnerNames(getUniqueOwnerNames(saleData));
  }, [saleData]);

  useEffect(() => {
    newfilterData();
  }, [selectedVNumber, selectedOwnerName, saleData]);

  const getUniqueVNumbers = (data) => {
    const vNumberArray = [];
    data?.forEach(item => {
      if (!vNumberArray.includes(item.vNumber)) {
        vNumberArray.push(item.vNumber);
      }
    });
    return vNumberArray;
  };

  const getUniqueOwnerNames = (data) => {
    const ownerNameArray = [];
    data?.forEach(item => {
      if (!ownerNameArray.includes(item.ownerName)) {
        ownerNameArray.push(item.ownerName);
      }
    });
    return ownerNameArray;
  };

  const newfilterData = () => {
    let filtered = saleData;
    if (selectedVNumber) {
      filtered = filtered.filter(item => item.vNumber === selectedVNumber);
    }
    if (selectedOwnerName) {
      filtered = filtered.filter(item => item.ownerName === selectedOwnerName);
    }
    setNewFilteredData(filtered);
  };

  
  let paidMoney=0,totalMoney=0;
  newfilteredData.forEach(obj => {
      if(obj.advanceAmount!=0){
        paidMoney+=obj.advanceAmount;
      }
      if(obj.advanceAmount==0){
        paidMoney+=obj.amount;
      }

      totalMoney+=obj.amount;
    });

  return (
    <div className="flex flex-col gap-5 " >
    {/* <div className=' pr-3 w-full align-middle flex justify-end ' >
         <div className='flex gap-10 ' >
       <label  >
         Filter by vNumber:
         <select value={selectedVNumber} 
         className=' ml-3 px-2 py-1 md:px-4 md:py-2 text-sm  rounded-md md:text-md shadow-2xl border-slate-400 border-[1px] outline-none cursor-pointer '
         onChange={(e) => setSelectedVNumber(e.target.value)}>
           <option value=''>All</option>
           {uniqueVNumbers.map((vNumber, index) => (
             <option key={index} value={vNumber}>
               {vNumber}
             </option>
           ))}
         </select>
       </label>
       <label >
         Filter by ownerName:
         <select value={selectedOwnerName} 
         className=' ml-3 px-2 py-1 md:px-4 md:py-2 text-sm  rounded-md md:text-md shadow-2xl border-slate-400 border-[1px] outline-none cursor-pointer '
         onChange={(e) => setSelectedOwnerName(e.target.value)}>
           <option value=''>All</option>
           {uniqueOwnerNames.map((ownerName, index) => (
             <option key={index} value={ownerName}>
               {ownerName}
             </option>
           ))}
         </select>
       </label>
     </div>
    </div> */}
     <div className="p-1.5 w-full inline-block align-middle">
       <div className="overflow-hidden border rounded-lg">
         <table className="w-full divide-y divide-gray-200">
           <thead className="bg-blue-500 w-full text-white ">
             <tr>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Name
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Email
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Mobile
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Source
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Services
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Date
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Time
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Next Calling Date
               </th>
               {/* <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Follow Up Result
               </th> */}
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Result
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Assigned To
               </th>
               <th
                 scope="col"
                 className="px-4
                  py-2 text-[12px] font-bold text-left uppercase "
               >
                 Response
               </th>
             </tr>
           </thead>
           {/* table body */}
           {
               loading && (
                   <div className='w-full' >
                       <Loader/>
                   </div>
               )
           }
           {
               !loading && (
                 newfilteredData?.map((user,index)=>(
                   <tbody className="divide-y divide-gray-200" key={index} >
       <tr className='bg-gray-50' >
               <td className="px-4
                py-3 text-[12px] font-semibold text-gray-800 whitespace-nowrap">
                 {user?.name}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">{user?.email}</td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.mobile}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.source}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.services}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.date}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.time}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.followDate}
               </td>
               {/* <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.followResult}
               </td> */}
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.result}
               </td>
               <td className="px-4
                py-3 text-[12px] text-gray-800 whitespace-nowrap">
               {user?.assignedTo}
               </td>
               {
                user?.response === 'Interest'
                 && (<td className="px-4
                py-3 text-[12px] text-green-500 font-bold whitespace-nowrap">
               {user?.response}
               </td>)
               }
               {
                user?.response === 'Not Pick'
                 && (<td className="px-4
                py-3 text-[12px] text-yellow-500 font-bold whitespace-nowrap">
               {user?.response}
               </td>)
               }
               {
                user?.response === 'Call Back'
                 && (<td className="px-4
                py-3 text-[12px] text-violet-500 font-bold whitespace-nowrap">
               {user?.response}
               </td>)
               }
               {
                user?.response === 'Not Interested'
                 && (<td className="px-4
                py-3 text-[12px] text-red-500 font-bold whitespace-nowrap">
               {user?.response}
               </td>)
               }
           
             </tr>
           </tbody>
               ))
               )
           }
         </table>
       </div>
     </div>
 </div>
  )
}

export default FilterNameTable