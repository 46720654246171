import './App.css';
import Login from './pages/Login'; 
import { Routes, Route } from "react-router-dom";
import Register from './pages/Register';
import Dashboard from './pages/Dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import  OpenRoute  from "./components/Auth/OpenRoute";
import PrivateRoute from "./components/Auth/PrivateRoute";
import HomePage from './pages/HomePage';
import AdminDashboard from './components/admin/AdminDashboard';
import UpdateSale from './pages/Dashboard/SaleComponent/UpdateSale';
import { useSelector } from 'react-redux';
import UpdateRbm from './pages/Dashboard/RbmComponent/UpdateRbm';
import UserProfile from './pages/Dashboard/UserProfile/UserProfile';
import TelecallerData from './components/Telecaller/TelecallerData';
import ForgotPassword from './pages/ForgotPassword';
import ResetComplete from './pages/ResetComplete';
import UpdatePassword from './pages/UpdatePassword';

function App() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.profile);
  
  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem("user") ) {
      if(user?.accountType === 'User'){
         navigate("/dashboard/my-profile");
      } else if(user?.accountType === 'Admin'){
        navigate("/admin-dashboard/my-profile");
      }
    }
  },[])

  return (
    <div className='w-[100%] h-full bg-white ' >
      <Routes>
      <Route path="/" element={
             <OpenRoute>
            <HomePage />
            </OpenRoute>
           }/>
           <Route path="/admin-dashboard/my-profile" 
           element={
            <PrivateRoute>
            <AdminDashboard />
            </PrivateRoute>
           }/>
      <Route path="/dashboard/my-profile" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
           }/>
       {/* Forgot Password */}
       <Route path='/reset-password' element={<ForgotPassword/>} />
      <Route path='/reset-complete' element={<ResetComplete/>} />
      <Route
        path="/update-password/:id"
        element={
            <UpdatePassword />
        }
      />  
      </Routes>
      <div className='w-11/12 h-full mx-auto ' >
        <Routes>
           <Route path="/register" 
           element={
            <PrivateRoute>
            <Register />
            </PrivateRoute>
           }/>
           <Route path="/login" element={<Login />}/>
           <Route path="/update-userdata/:id"
            element={
              <PrivateRoute>
                 <UserProfile />
              </PrivateRoute>
           }/>
           <Route path="/update-rbm/:id"
            element={
              <PrivateRoute>
                 <UpdateRbm />
              </PrivateRoute>
           }/>
           <Route path="/telecaller-details/:id"
            element={
              <PrivateRoute>
                 <TelecallerData />
              </PrivateRoute>
           }/>

        </Routes>
      </div>
    </div>
  );
}

export default App;
