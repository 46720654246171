import React, { useEffect, useState } from 'react'
import Papa from "papaparse";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uploadFile } from '../services/authAPI';
import { BASE_URL } from '../BaseURL';
import { apiConnector } from '../services/apiConnector';
import { setLoading } from '../slices/authSlice';

const CsvFileInput = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [id, setId] = useState('');
  const [teleData, setTeleData] = useState([]);

  const handleFileLoad = (csvData) => {
    setData(csvData);
  };

  const handleSubmit = async(e) =>{
    e.preventDefault();

    dispatch(uploadFile(
      id,
      data,
      navigate
    ))

  }

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      
      if (file) {
        Papa.parse(file, {
          complete: (result) => {
            handleFileLoad(result.data);
          },
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
      }
    };


    // get all telecallers
    const getAllTeleCallers = async()=>{
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("get",`${BASE_URL}/telecaller/get-all-telecaller`);

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          setTeleData(response.data.data);

      } catch (error) {
          console.log("Can't fetch Data due to",error);
      }
      dispatch(setLoading(false));
  }

  useEffect(()=>{
    getAllTeleCallers();
  },[])

    return (
      <div>
          <form className=' flex flex-col gap-10 items-center justify-center ' >
          <div className='flex gap-10 ' >
         <label  >
        Telecallers List:
         <select value={id} 
         className=' ml-3 px-1 py-1 text-sm  rounded-md md:text-md shadow-2xl border-slate-400 border-[1px] outline-none cursor-pointer '
         onChange={(e) => setId(e.target.value)}>
           <option value=''>Select Telecaller</option>
           {teleData?.map((tele, index) => (
             <option key={index} value={tele?._id} >
               {tele?.fullName}
             </option>
           ))}
         </select>
       </label>
       <div>
        <input type="file" onChange={handleFileChange}
        className=' text-center block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 py-1 px-2  '
         />
        </div>
        </div>
        <button onClick={handleSubmit}
         className=' bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-200 '
        >
          Submit</button>
      </form>
      </div>
    );
  };
  export default CsvFileInput;