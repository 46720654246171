import React from 'react'
import { useSelector } from 'react-redux';

const AdminHeader = () => {
    const { user } = useSelector((state) => state.profile);

  return (
    <div className=' w-[100%] md:w-[80%] flex py-2 px-3 md:px-6 bg-[#081321] fixed border-b-[2px] border-b-black top-0 lg:justify-end items-center font-poppins ' >
        <div className='flex flex-col gap-1 text-blue-500 ' >
            <p className=' font-semibold text-[12px] lg:text-[14px]' >{user?.fullName}</p>
            <p className=' font-semibold text-[12px] lg:text-[14px]' >{`${user?.email} [${user?.accountType}]`}</p>
            
        </div>
    </div>
  )
}

export default AdminHeader