import React, { useState } from 'react'
import LoginImg from "../assests/log.png";
import mailImg from "../assests/mail.png";
import PassImg from "../assests/pass.png";
import {Link, useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { login } from '../services/authAPI';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        email:'',
        password:'',
    });

    const handleInputChange = (e) =>{
        setFormData({...formData,
            [e.target.name]: e.target.value
    })}

    const handleSubmit = (e) =>{
        e.preventDefault();
        dispatch(login(formData.email, formData.password, navigate))
    }

//     const [accountType, setAccountType] = useState('User');

//   const handleToggleAdmin = (e) => {
//     e.preventDefault();
//         setAccountType('Admin');
//   };

//   const handleToggleUser = (e) => {
//     e.preventDefault();
//         setAccountType('User');
//   };

  return (
    <div className='w-[100%] h-full flex justify-between gap-[100px] font-poppins' >
        {/* image */}
        {/* <div className='hidden lg:flex items-center' >
            <img src={LoginImg} width="600px" loading='lazy' alt='Side Image' />
        </div> */}
        {/* Content */}
        <div className='w-full lg:w-[100%] h-[100%] bg-[white] rounded-md flex flex-col px-8 py-8 ' >
            <p className='font-medium text-[20px]' >Welcome </p>
            <h2 className='font-bold text-sm text-blue-500 font-poppins '
            >Login to your Account</h2>
            <div className='w-full h-[1px] bg-[#BFBFBF] mt-7 ' ></div>
            <form className='flex flex-col gap-2' >
            {/* <div className="flex p-1 gap-x-1 my-6 rounded-full max-w-max">
                <button
                        className={`${accountType === "User"
                        ?"bg-black text-white" 
                        : "bg-transparent text-gray-600"}
                        py-2 px-5 rounded-full transition-all duration-200`}
                        onClick={handleToggleUser}
                    >
                        User
                </button>
                <button
                    className={`${accountType === "Admin"
                    ? "bg-black text-white"
                    : "bg-transparent text-gray-400"} 
                    py-2 px-5 rounded-full transition-all duration-200`}
                    onClick={handleToggleAdmin}
                >
                    Admin
                </button>
            </div> */}
                <div className='flex flex-col gap-4 mt-5 ' >
                            <div>
                                <label htmlFor='email' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Email Address <sup className='text-[#FF0000] ' >*</sup> </p>  </label>
                                    <input
                                        required
                                        type='email'
                                        id='email'
                                        name='email'
                                        placeholder='Enter your email'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                        </div>
                        <div className='flex flex-col gap-4 mt-5 ' >
                            <div>
                                <label htmlFor='password' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Password <sup className='text-[#FF0000] ' >*</sup> </p>  </label>
                                    <input
                                        required
                                        type='password'
                            id='password'
                            name='password'
                            placeholder='Enter your password'
                            value={formData.password}
                            onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                        </div>
                <div className=' mt-3 flex justify-end ' >
                    <Link to="/reset-password" className=' text-sm text-blue-500 transition-all duration-200 hover:text-blue-600 ' >
                        Forgot Password
                    </Link>
                </div>
             
                <button onClick={handleSubmit} 
                className='bg-blue-500 mt-3 font-semibold text-white px-1 py-3 rounded-md
                 hover:bg-blue-600 transition-all duration-300' 
                >Login</button>
            </form>
        </div>
    </div>
  )
}

export default Login