import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../BaseURL.js';
import { apiConnector } from '../../../services/apiConnector.js';
import { setLoading } from '../../../slices/authSlice.js';
import toast from 'react-hot-toast';
import './TodayTable.css';
import { Link } from 'react-router-dom';
import Loader from '../../../Loading/Loader.jsx';
import { BsPencilSquare } from "react-icons/bs";
import { RiInformationLine } from "react-icons/ri";


const TodayTable = () => {
    const date = new Date();
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const weeks = ["Sunday","Monday","Tuesday","Thursday","Friday","Saturday"];
  const d = new Date();
  let month = months[d.getMonth()];
  let day = weeks[d.getDay()];
  let dat = d.getDate();

  const [leadData, setLeadData] = useState([]);
    const {loading} = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.profile);
    const dispatch = useDispatch();
  
    const Id = user._id;

    const getAllLeads = async()=>{
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("get",`${BASE_URL}/telecaller/get-one-telecaller/${Id}`);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            setLeadData(response.data.data.UsersData);

        } catch (error) {
            console.log("Can't fetch Data due to",error);
            toast.error("Leads data can't be fetched");
        }
        dispatch(setLoading(false));
    }

    useEffect(()=>{
      getAllLeads();
    },[])

  const todayDate = new Date().toISOString().split('T')[0];

    // Filter data for today's date
    const todayData = leadData.filter(item => item.uploadedOnDate.startsWith(todayDate));

  return (
    <div className="flex flex-col " >
    <div >
    {
      todayData.length === 0 && (
      <div className=' flex items-center justify-center gap-2 bg-blue-200 px-2 py-2 rounded-md text-blue-600 ' >
        <RiInformationLine/>
        <p >No Data Assigned Today</p>
      </div>)
    }
    {
      todayData.length > 0 && (<div className="p-1.5 w-full inline-block align-middle">
      <div className="overflow-hidden border rounded-lg">
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-blue-500 w-full text-white ">
            <tr>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Name
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Email
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Mobile
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Source
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Services
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Date
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Time
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Follow Date
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Follow Up Result
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Response
              </th>
              <th
                scope="col"
                className="px-4
                 py-2 text-[12px] font-bold text-left uppercase "
              >
                Update
              </th>
            </tr>
          </thead>
          {/* table body */}
          {
              loading && (
                  <div className='w-full' >
                      <Loader/>
                  </div>
              )
          }
          {
              !loading && (
               todayData?.map((user,index)=>(
                  <tbody className="divide-y divide-gray-200" key={index} >
      <tr className='bg-gray-50' >
              <td className="px-4
               py-3 text-[12px] font-semibold text-gray-800 whitespace-nowrap">
                {user?.name}
              </td>
              <td className="px-4
               py-3 text-[12px] text-gray-800 whitespace-nowrap">{user?.email}</td>
              <td className="px-4
               py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {user?.mobile}
              </td>
              <td className="px-4
               py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {user?.source}
              </td>
              <td className="px-4
               py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {user?.services}
              </td>
              <td className="px-4
               py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {user?.date}
              </td>
              <td className="px-4
               py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {user?.time}
              </td>
              <td className="px-4
               py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {user?.followDate}
              </td>
              <td className="px-4
               py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {user?.followResult}
              </td>
              {
               user?.response === 'Interest'
                && (<td className="px-4
               py-3 text-[12px] text-green-500 font-bold whitespace-nowrap ">
              {user?.response}
              </td>)
              }
              {
               user?.response === 'Not Pick'
                && (<td className="px-4
               py-3 text-[12px] text-yellow-500 font-bold whitespace-nowrap ">
              {user?.response}
              </td>)
              }
              {
               user?.response === 'Call Back'
                && (<td className="px-4
               py-3 text-[12px] text-violet-500 font-bold whitespace-nowrap ">
              {user?.response}
             
              </td>)
              }
              {
               user?.response === 'Not Interested'
                && (<td className="px-4
               py-3 text-[12px] text-red-500 font-bold whitespace-nowrap ">
              {user?.response}
              </td>)
              }
               <td className="px-4 text-[12px] font-semibold whitespace-nowrap">
                           <Link to={`/update-userdata/${user?._id}`}
                           >
                               <button className=" px-4 py-2 rounded-md  text-white bg-blue-500 hover:bg-blue-600">
                               <BsPencilSquare />
                               </button>
                           </Link>
                         </td>
            </tr>
          </tbody>
              ))
              )
          }
        </table>
      </div>
    </div>)
    }
    </div>
  </div>
  )
}

export default TodayTable