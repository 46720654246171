import React, { useEffect, useState } from 'react'
import Papa from "papaparse";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiConnector } from '../../../services/apiConnector';
import { BASE_URL } from '../../../BaseURL';
import { setLoading } from '../../../slices/authSlice';
import { uploadFile } from '../../../services/authAPI';
import file from "../../../assests/file/sample.csv";

const UploadCSVTelecaller = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [id, setId] = useState('');
  const [teleData, setTeleData] = useState([]);

  const { user } = useSelector((state) => state.profile);

  useEffect(()=>{
    setId(user?._id);
  },[])

  const handleFileLoad = (csvData) => {
    setData(csvData);
  };

  const handleSubmit = async(e) =>{
    e.preventDefault();

    dispatch(uploadFile(
      id,
      data,
      navigate
    ))

  }

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      
      if (file) {
        Papa.parse(file, {
          complete: (result) => {
            handleFileLoad(result.data);
          },
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
      }
    };

    const onButtonClick = () => {
    
        // using Java Script method to get PDF file
        fetch(`${file}`).then((response) => {
            response.blob().then((blob) => {
            
                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);
                    
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "SampleFile.csv";
                alink.click();
            });
        });
    };

    return (
      <div>
          <form className=' flex flex-col gap-10 items-center justify-center ' >
          <div className='flex flex-col gap-3 mt-5 ' >  
       <div>
        <input type="file" onChange={handleFileChange}
        className=' text-center block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 py-1 px-2  '
         />
        </div>
        </div>
        <button onClick={handleSubmit}
         className=' bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-200 '
        >
          Submit</button>
      </form>
      <div className=' flex flex-col gap-2 items-center mt-[100px] ' >
          <h3 className=' font-semibold  ' >Note :- Here's Sample CSV File to Upload</h3>
           <button onClick={onButtonClick} 
           className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-200 '
           >
                    Download Sample
                </button>
        </div>
      </div>
    );
  };

export default UploadCSVTelecaller